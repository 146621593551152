.popover.popover-primary .popover-title {
  color: #ffffff;
  background-color: #2c5ca9;
  border-color: #2c5ca9;
}
.popover.bottom.popover-primary .arrow {
  border-bottom-color: #2c5ca9;
}
.popover.bottom.popover-primary .arrow:after {
  border-bottom-color: #2c5ca9;
}
.popover.popover-success .popover-title {
  color: #ffffff;
  background-color: #00c292;
  border-color: #00c292;
}
.popover.bottom.popover-success .arrow {
  border-bottom-color: #00c292;
}
.popover.bottom.popover-success .arrow:after {
  border-bottom-color: #00c292;
}
.popover.popover-info .popover-title {
  color: #ffffff;
  background-color: #008efa;
  border-color: #008efa;
}
.popover.bottom.popover-info .arrow {
  border-bottom-color: #008efa;
}
.popover.bottom.popover-info .arrow:after {
  border-bottom-color: #008efa;
}
.popover.popover-warning .popover-title {
  color: #ffffff;
  background-color: #f8c255;
  border-color: #f8c255;
}
.popover.bottom.popover-warning .arrow {
  border-bottom-color: #f8c255;
}
.popover.bottom.popover-warning .arrow:after {
  border-bottom-color: #f8c255;
}
.popover.popover-danger .popover-title {
  color: #ffffff;
  background-color: #f75b36;
  border-color: #f75b36;
}
.popover.bottom.popover-danger .arrow {
  border-bottom-color: #f75b36;
}
.popover.bottom.popover-danger .arrow:after {
  border-bottom-color: #f75b36;
}